import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import LayoutOrig from "../components/layout_en"
import Seo from "../components/seo"
import {Badge, Col, Container, Row} from "react-bootstrap";
import {GatsbyImage} from "gatsby-plugin-image";
import { IoArrowBackOutline } from "@react-icons/all-files/io5/IoArrowBackOutline";



const BlogPost = ({ pageContext}) => {
    const { smallImage,headerImage,authorImage,date,author,titleSeo,titleTemplateSeo,timeToRead,embeddedImagesRemote,body, title,descrSeo} = pageContext;
    return (
        <LayoutOrig>
            <Seo
                titleTemplate={titleTemplateSeo}
                title={titleSeo}
                description={descrSeo}
                image={smallImage}
                lang="en"
            />
            <Fragment>
                <div className="header-box-article" style={{backgroundColor: "#F9F9F9"}}>
                    <Container className="justify-content-center pb-5">
                        <Link to="/en/blog/"><IoArrowBackOutline></IoArrowBackOutline> Go back Blog</Link>

                        <Row>

                            <Col md={{span: 2, offset: 0}} className="justify-content-center"></Col>

                            <Col md={{span: 8, offset: 0}}>
                                <h1 className="pt-4" style={{alignContent:"center", fontWeight:"bold"}}>{title}</h1>
                                <Row className="text-center pt-4 pb-3">
                                    <Col md={1}>
                                        <GatsbyImage  image={authorImage?.childImageSharp.gatsbyImageData} alt="text a définir"/>
                                    </Col>
                                    <Col md={10}>
                                        <Row style={{fontSize: "16px"}}>
                                            {author}
                                        </Row>
                                        <Row style={{fontSize: "12px"}}>
                                            {date}
                                        </Row>


                                    </Col>
                                    <Row className="pt-3" style={{fontSize: "14px", width: "25%"}}>
                                        <Badge pill bg="primary" style={{fontWeight:"normal"}}>
                                            Temps de lecture : {timeToRead} min
                                        </Badge>
                                    </Row>

                                </Row>

                                <Row className="justify-content-center" style={{
                                    backgroundColor: "#FFFFFF",
                                    boxShadow: "rgb(0 0 0 / 5%) 0px 0px 10px 0px",
                                    borderRadius: "10px",

                                }}>



                                    <Row style={{paddingLeft:"0px", paddingRight:"0px", boxShadow: "rgb(0 0 0 / 5%) 0px 0px 10px 0px"}}>
                                        <GatsbyImage style={{ borderStartEndRadius:"10px", borderStartStartRadius:"10px"}} image={headerImage?.childImageSharp.gatsbyImageData}
                                                     alt="text a définir"/>
                                    </Row>

                                    <Row style={{padding: "2rem"}}>


                                        <MDXProvider
                                            components={{ h2: props => <h2 {...props} className="blog" />,
                                                h3: props => <h3 {...props} className="blog" />,
                                                h4: props => <h4 {...props} className="blog" />,
                                                h5: props => <h5 {...props} className="blog" />,
                                            }}
                                        >
                                            <MDXRenderer
                                                remoteImages={embeddedImagesRemote}
                                            >
                                                {body}
                                            </MDXRenderer>
                                        </MDXProvider>


                                    </Row>

                                </Row>
                            </Col>
                            <Col md={{span: 2, offset: 0}} className="justify-content-center"></Col>
                        </Row>


                    </Container>
                </div>

            </Fragment>
        </LayoutOrig>
    );
};

export default BlogPost;





